import React, {useContext, useEffect, useMemo, useState} from "react";
import {Form, Button, Alert} from "react-bootstrap";
import {xhr} from "../index";
import Field from "./field";
import {Loading} from "../";
import GetForeignData from "../getForeignData";
import {DBContext} from "../../providers/dbProvider";
import moment from 'moment-jalaali';
import qs from 'qs';

export default function Item(props) {
    const [message, setMessage] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [mapCircle, setMapCircle] = useState({});
    const [foreignValues, setForeignValues] = useState({});
    const {collections, getData} = useContext(DBContext);

    const base = useMemo(() => {
        return props.props.base;
    }, []);
    const item = useMemo(() => {
        return props.props.item;
    }, []);
    const title = useMemo(() => {
        let temp = '';
        if(item.id){
            setId(item.id);
            temp = item.custom_edit_title ?? 'ویرایش ' + base.entity;
        }else{
            temp = item.custom_add_title ?? 'افزودن ' + base.entity + ' جدید';
        }
        return temp;
    }, []);

    const afterChange = (name, value) => {
        if(name === "radius"){
            setMapCircle(mapCircle.change("radius", value));
        }
    };

    const changeField = (name, value) => {
        if(base.model[name].value_type === "boolean"){
            value = (value === "true");
        }
        setData({...data, [name]: value});
        if(base.model[name] && base.model[name].afterChange){
            afterChange(name, value);
        }
    };

    const changeFieldsBatch = fields => {
        setData({...data, ...fields});
    };

    const showResult = response => {
        if(response.status){
            setMessage(<Alert variant="success">{response.note}</Alert>);
        }else{
            setMessage(<Alert variant="danger">{response.note}</Alert>);
        }
        window.scroll(0, 0);
        setLoading(false);
    };

    const submit = e => {
        e.preventDefault();
        setLoading(true);
        let sendData = {...data};
        try {
            let JSONFields = props.props.item.fields.filter(field => field.type === "json");
            if(JSONFields.length > 0){
                for(let i = 0; i < JSONFields.length; i++){
                    sendData[JSONFields[i].name] = JSON.parse(sendData[JSONFields[i].name]);
                }
            }
            if(id){
                let modelFields = Object.keys(base.model);
                let sendDataKeys = Object.keys(sendData);
                for(let i = 0; i < sendDataKeys.length; i++){
                    if(modelFields.indexOf(sendDataKeys[i]) === -1){
                        delete sendData[sendDataKeys[i]];
                    }
                }
                sendData.id = id;
                new xhr({
                    url: base.module,
                    data: sendData
                }).Put(showResult);
            }else{
                new xhr({
                    url: base.module,
                    data: sendData
                }).Post(showResult);
            }
        } catch (e) {
            setMessage(<Alert variant="danger">{e.message}</Alert>);
        }
    };

    useEffect(() => {
        if(id) {
            new xhr({
                url: base.module + '/' + id,
                server: base.server
            }).GetOne(response => {
                let JSONFields = props.props.item.fields.filter(field => field.type === "json");
                if(JSONFields.length > 0){
                    for(let i = 0; i < JSONFields.length; i++){
                        response[JSONFields[i].name] = JSON.stringify(response[JSONFields[i].name]);
                    }
                }
                let dateFields = Object.keys(base.model).filter(key => base.model[key].type === "date" || base.model[key].type === "datetime");
                if(dateFields.length > 0){
                    for(let i = 0; i < dateFields.length; i++){
                        if(base.model[dateFields[i]].type === "date"){
                            response[dateFields[i]] = moment(response[dateFields[i]], 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD');
                        }else{
                            response[dateFields[i]] = moment(response[dateFields[i]], 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss');
                        }
                    }
                }
                setData(response);
            });
        }else{
            let firstData = {};
            let field;
            for(let i = 0; i < props.props.item.fields.length; i++){
                field = props.props.item.fields[i];
                if(field.default !== undefined){
                    firstData[field.name] = field.default;
                }
            }

            let queryParams = window.location.search;
            let params = null;
            if(queryParams){
                params = qs.parse(queryParams, {ignoreQueryPrefix: true});
            }
            if(params){
                let modelFields = Object.keys(base.model);
                for(let i = 0; i < modelFields.length; i++){
                    if(params[modelFields[i]]){
                        firstData[modelFields[i]] = params[modelFields[i]];
                    }
                }
            }
            setData(props.props.item.initial_data ?? firstData);
        }

        GetForeignData({
            collections,
            getData,
            model: base.model,
        }, fv => setForeignValues(fv));
    }, []);

    return (
        <div>
            <h2>{title}</h2>
            {message}
            {data && (Object.keys(data).length > 0 || !id) ?
                <Form onSubmit={submit}>
                    {item && item.fields.map(field => <Field key={`field_${field.name}`} props={{
                        id,
                        base,
                        field,
                        data,
                        mapCircle,
                        foreignValues,
                        changeField,
                        changeFieldsBatch
                    }}/>)}
                    <Button type="submit" disabled={loading ? "true" : ""}>{loading ? <Loading theme="light"/> : "ثبت"}</Button>
                </Form>
                :
                <Loading/>
            }
        </div>
    );
}