let id = 1;
module.exports = [
    {
        id: id++,
        title: "اطلاعات پایه",
        options: [
            {id: id++, module: "users", title: "کاربران", path: "/users"},
            {id: id++, module: "cities", title: "شهرها و استان ها", path: "/cities"},
            {id: id++, module: "categories", title: "دسته بندی ها", path: "/categories"},
            {id: id++, module: "pins", title: "پین ها", path: "/pins"},
            {id: id++, module: "articles", title: "مطالب", path: "/articles"},
            {id: id++, module: "banned_phrases", title: "عبارات ممنوعه", path: "/banned_phrases"},
            {id: id++, module: "user_contacts", title: "کانتکت ها", path: "/user_contacts"},
            {id: id++, module: "user_contact_groups", title: "گروه های کانتکت ها", path: "/user_contact_groups"},
            {id: id++, module: "scores", title: "امتیازات", path: "/scores"},
            {id: id++, module: "plugins", title: "ابزارک ها", path: "/plugins"},
        ]
    },
    {
        id: id++,
        title: "کسب و کارها",
        badge: ["services", "claims", "services_comment"],
        options: [
            {id: id++, module: "services", title: "کسب و کارها", path: "/businesses", badge: "services"},
            {id: id++, module: "selected_services", title: "کسب و کارهای برگزیده", path: "/selected_services"},
            {id: id++, module: "services", component: "complexes", title: "مجتمع های تجاری", path: "/complexes"},
            {id: id++, module: "requests", title: "درخواست ها", path: "/requests"},
            {id: id++, module: "claims", title: "درخواست های مالکیت", path: "/claims", badge: "claims"},
            {id: id++, module: "services_comment", title: "نظرات", path: "/comments", badge: "services_comment"},
            {id: id++, module: "tags", title: "تگ ها", path: "/tags"},
            {id: id++, module: "services", title: "نقشه کسب و کارها", path: "/business_map"},
        ]
    },
    {
        id: id++,
        title: "پست ها",
        badge: ["posts", "post_comments"],
        options: [
            {id: id++, module: "posts", title: "پست ها", path: "/posts", badge: "posts"},
            {id: id++, module: "post_comments", title: "نظرات پست ها", path: "/post_comments", badge: "post_comments"},
        ]
    },
    {
        id: id++,
        title: "اخبار",
        options: [
            {id: id++, module: "news", title: "اخبار", path: "/news"},
        ]
    },
    {
        id: id++,
        title: "بازخوردها",
        options: [
            {id: id++, module: "abuse", title: "گزارشات تخلف", path: "/abuse"},
            {id: id++, module: "feedback", title: "بازخوردها", path: "/feedback"},
            {id: id++, module: "my_conversations", title: "پشتیبانی", path: "/support", custom_route: true},
        ]
    },
    {
        id: id++,
        title: "ارتباط با مشتریان",
        options: [
            {id: id++, module: "crm_subjects", title: "موضوعات", path: "/crm_subjects"},
            {id: id++, module: "crm", title: "تماس ها", path: "/crm"}
        ]
    },
    {
        id: id++,
        title: "تبلیغات",
        badge: ["reserve_billboards", "sponsor_packages_orders"],
        options: [
            {id: id++, module: "sponsor_packages", title: "پکیج های تبلیغاتی", path: "/sponsor_packages"},
            {id: id++, module: "pin_promotions", title: "پین های ویژه", path: "/pin_promotions"},
            {id: id++, module: "list_promotions", title: "آگهی های اسپانسری", path: "/list_promotions"},
            {id: id++, module: "sponsor_packages_orders", title: "سفارشات آگهی اسپانسری", path: "/sponsor_packages_orders", custom_route: true, badge: "sponsor_packages_orders"},
            {id: id++, module: "billboards", title: "بیلبورد مجازی", path: "/billboards"},
            {id: id++, module: "reserve_billboards", title: "رزرو بیلبوردها", path: "/reserve_billboards", badge: "reserve_billboards"},
            {id: id++, module: "campaigns", title: "کمپین ها", path: "/campaigns"},
            {id: id++, module: "advertisements", title: "تبلیغات", path: "/advertisements"},
        ]
    },
    {
        id: id++,
        title: "مالی",
        options: [
            {id: id++, module: "wallet", title: "کیف پول", path: "/wallet"},
            {id: id++, module: "user_achievements", title: "میز کوین", path: "/user_achievements"},
            {id: id++, module: "achievements", title: "دریافتی ها", path: "/achievements"},
            {id: id++, module: "gift_codes", title: "کدهای هدیه", path: "/gift_codes"},
            {id: id++, module: "import_charges", title: "ارسال دسته ای شارژ", path: "/import_charges", custom_route: true},
            {id: id++, module: "gift_histories", title: "تاریخچه هدایا", path: "/gift_histories"},
        ]
    },
    {
        id: id++,
        title: "پین و وین",
        options: [
            {id: id++, module: "gift_pins", title: "پین های هدیه", path: "/gift_pins"},
            {id: id++, module: "gifts", title: "هدایا", path: "/gifts"},
            {id: id++, module: "gift_details", title: "مقدایر هدایا", path: "/gift_details"},
        ]
    },
    {
        id: id++,
        title: "گزارشات",
        options: [
            {id: id++, module: "dashboard", title: "گزارش کلی", path: "/reports_dashboard", custom_route: true},
            {id: id++, module: "logs", title: "گزارش اختصاصی", path: "/custom_report"},
            {id: id++, module: "gift_codes", title: "گزارش شارژها", path: "/gift_report", custom_route: true}
        ]
    },
    {
        id: id++,
        title: "متون",
        options: [
            {id: id++, module: "server_messages", title: "پیام‌های سیستمی", path: "/server_messages"},
            {id: id++, module: "server_notifications", title: "نوتیفیکیشن ها", path: "/server_notifications"},
            {id: id++, module: "server_sms", title: "پیامک ها", path: "/server_sms"},
        ]
    },
    {
        id: id++,
        title: "بخش های سیستمی",
        options: [
            {id: id++, module: "recommendations", title: "پیشنهادات", path: "/recommendations"},
            {id: id++, module: "comments_queue", title: "صف ارسال نظرات", path: "/comments_queue"},
            {id: id++, module: "test_cases", title: "تست ها", path: "/test_cases"},
            {id: id++, module: "versions", title: "نسخه ها", path: "/versions"},
            {id: id++, module: "logs", title: "لاگ ها", path: "/logs"},
            {id: id++, module: "mobile_log", title: "لاگهای موبایل", path: "/mobile_log"},
            {id: id++, module: "customlog", title: "گزارشات لاگ ها", path: "/logs_report", custom_route: true},
            {id: id++, module: "download_db", title: "دانلود بکاپ", path: "/backup"},
            {id: id++, module: "console", title: "کنسول", path: "/console"},
            {id: id++, module: "events", title: "رویدادها", path: "/events"},
            {id: id++, module: "sessions", title: "نشست ها", path: "/sessions"},
            {id: id++, module: "generate_lottery_codes", title: "تولید کد قرعه کشی", path: "/lottery_codes", custom_route: true},
        ]
    },
];
