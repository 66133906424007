const businesses = props => {
    return {
        base: {
            entities: "کسب و کارها",
            entity: "کسب و کار",
            module: "services",
            path: "/businesses",
            default_order: {last_update: -1},
            confirm_field: "confirmed",
            confirmed_extra_fields: ["mobile_confirmed"],
            confirm_other_fields: [{name: "user.id", alias: "user_id"}],
            model: {
                images: {title: "تصویر", type: "images"},
                title: {title: "نام"},
                service_type: {title: "نوع"},
                category_id: {
                    title: "دسته بندی",
                    type: "picker",
                    module: "categories",
                    caption: "category_name",
                    foreign: {
                        module: "categories",
                        path: "/categories",
                        field: "id",
                        result: ["name"],
                    },
                    other_fields: [
                        {name: "name", as: "category_name"},
                    ]
                },
                category_name: {title: "دسته بندی"},
                parent_id: {title: "مجتمع تجاری"},
                user: {
                    title: "مدیر",
                    type: "picker",
                    module: "users",
                    caption: [
                        "user.name",
                        {static: " "},
                        "user.family"
                    ]
                },
                description: {title: "توضیحات"},
                city_id: {
                    title: "شهر",
                    type: "picker",
                    module: "cities",
                    caption: "city_name"
                },
                city_name: {title: "شهر"},
                address: {title: "آدرس"},
                code: {title: "کد"},
                phone: {title: "تلفن"},
                email: {title: "ایمیل"},
                web: {title: "وبسایت"},
                telegram: {title: "تلگرام"},
                instagram: {title: "اینستاگرام"},
                working_time: {title: "ساعات کاری", type: "json"},
                person_type: {
                    title: "نوع کسب و کار",
                    type: "radio",
                    items: [
                        {key: true, value: "حقوقی"},
                        {key: false, value: "حقیقی"}
                    ]
                },
                postal_code: {title: "کد پستی"},
                location: {title: "موقعیت", type: "map"},
                coverage_type: {title: "سطح خدمت دهی"},
                fax: {title: "فکس"},
                category_type: {
                    title: "نوع کسب و کار",
                    type: "radio",
                    items: [
                        {key: "private", value: "خصوصی"},
                        {key: "public", value: "عمومی"}
                    ]
                },
                confirmed: {title: "تأیید", type: "checkbox"},
                admin_note: {title: "دلیل رد شدن"},
                tags: {
                    title: "تگ ها",
                    type: "tag_data",
                    foreign: {
                        module: "tags",
                        path: "/tags",
                        field: "id",
                        result: ["name"]
                    }
                },
                url_name: {title: "آدرس صفحه"},
                business_type: {
                    title: "نوع کسب و کار",
                    type: "select",
                    items: [
                        {key: "services", value: "خدمات محور"},
                        {key: "products", value: "کالا محور"},
                        {key: "general", value: "عمومی"}
                    ]
                }
            },
            picker: {
                params: ["title", "phone"],
                caption: ["title"],
                fields: [
                    {name: "title"},
                    {
                        title: "مدیر",
                        type: "multiple",
                        separator: " ",
                        result: [
                            {name: "user.name", type: "nested"},
                            {name: "user.family", type: "nested"}
                        ]
                    },
                    {name: "city_name"}
                ]
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "images"},
                {name: "title"},
                {name: "business_type"},
                {name: "service_type"},
                {name: "category_id", type: "foreign"},
                {
                    title: "مدیر",
                    type: "multiple",
                    separator: " ",
                    result: [
                        {name: "user.name", type: "nested"},
                        {name: "user.family", type: "nested"}
                    ]
                },
                {name: "description", max_length: 20},
                {
                    title: "مکان",
                    type: "multiple",
                    separator: " - ",
                    result: [
                        {name: "city_name"},
                        {name: "address", max_length: 20}
                    ]
                },
                {
                    title: "تماس",
                    type: "multiple",
                    style: {"direction": "ltr"},
                    separator: " - ",
                    result: [
                        {name: "code"},
                        {name: "phone"},
                        {name: "email"},
                        {name: "web"},
                        {
                            name: "telegram",
                            type: "social",
                            url: "https://www.t.me/",
                            icon: "telegram"
                        },
                        {
                            name: "instagram",
                            type: "social",
                            url: "https://www.instagram.com/",
                            icon: "instagram"
                        },
                    ]
                },
                {
                    title: "نمایش",
                    type: "function",
                    value: {
                        func: props.functions.showBiz,
                        params: ["id", "url_name", "confirmed"]
                    }
                },
                {name: "create_date", type: "date"}
            ],
            export_fields: [
                {name: "title"},
                {name: "description"},
                {name: "city_name"},
                {name: "phone"},
            ],
            search: [
                {name: "title"},
                {
                    name: "category_id",
                    field_type: "picker",
                    module: "categories",
                }
            ],
            custom_operations: [
                {
                    className: "primary",
                    style: {marginLeft: "5px"},
                    caption: {icon: 'CommentDiscussionIcon'},
                    click: {
                        func: props.functions.goToCRM,
                        params: ["id", "title", "phone"]
                    }
                }
            ],
            operations_style: {width: '220px'},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "images"},
                {name: "title"},
                {name: "description", type: "textarea"},
                {name: "business_type"},
                {name: "category_id"},
                {name: "tags"},
                {name: "user"},
                {name: "person_type"},
                {name: "city_id"},
                {name: "address"},
                {name: "postal_code"},
                {name: "location"},
                {name: "code", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "phone", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "fax", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "email", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "web", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "telegram", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "instagram", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "working_time"},
                {name: "category_type"},
                {name: "url_name"},
                {name: "confirmed"},
                {
                    name: "admin_note",
                    type: "function",
                    value: {
                        func: props.functions.adminNotes,
                        params: ["id", "confirmed", "admin_note"]
                    }
                },
            ],
        },
    };
};

export default businesses;