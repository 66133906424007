import React, {useMemo, useState} from "react";
import {Form, Button} from "react-bootstrap";
import xhr from "../xhr";
import moment from 'moment-jalaali';
import DatePicker from 'react-datepicker2';
import Loading from "../loading";
import {StyledImage} from "../image";
import Map from "../map";
import Picker from "../list/picker";
import ReactTags from 'react-tag-autocomplete';

const MashhadPosition = {lat: 36.300191, lng: 59.563351};

export default function Field(props) {
    let {
        id,
        base,
        field,
        data,
        mapCircle,
        foreignValues,
        changeField,
        changeFieldsBatch
    } = props.props;
    let fieldModel = useMemo(() => base.model[field.name], []);
    let value = data[field.name];

    let mapPosition = value && value.coordinates ?
        {
            lat: value.coordinates[1],
            lng: value.coordinates[0]
        }
        :
        MashhadPosition;

    if(!fieldModel){
        fieldModel = {};
    }
    const [dateField, setDateField] = useState(fieldModel.type === "date" && value ? moment(value, 'YYYY/MM/DD') : null);
    const [uploading, setUploading] = useState(false);
    const [tags, setTags] = useState([]);

    const fieldChange = e => {
        let target = e.target;
        let value;
        if(target.type === "checkbox") {
            value = target.checked;
        }else{
            value = target.value;
        }
        changeField(field.name, value);
    };

    const upload = file => {
        const simpleFile = document.getElementById("simple-" + field.name);
        if(simpleFile){
            file = simpleFile.files[0];
        }
        let data = new FormData();
        data.append('image', file);
        data.append('type', base.module);
        setUploading(true);
        new xhr({
            url: 'upload',
            data
        }).Post(response => {
            setUploading(false);
            let link = response.data && response.data.item ? response.data.item.link : null;
            if(link){
                if(fieldModel.type === "image" || fieldModel.type === "simple_image"){
                    changeField(field.name, link);
                }
                if(fieldModel.type === "images"){
                    if(value.length > 0){
                        value.push(link);
                        changeField(field.name, value);
                    }else{
                        changeField(field.name, [link]);
                    }
                }
            }
        });
    };

    const removeImage = () => {
        changeField(field.name, '');
    };

    const changeDate = value => {
        changeField(field.name, value.format('YYYY/MM/DD'));
        setDateField(value);
    };

    const changeDateTime = value => {
        changeField(field.name, value.format('YYYY/MM/DD HH:mm:ss'));
        setDateField(value);
    };

    const clickOnMap = e => {
        changeField(field.name, {
            type: "Point",
            coordinates: [e.latLng.lng(), e.latLng.lat()]
        });
    };

    const changePicker = args => {
        if(args.other){
            let fields = {[field.name]: args.id};
            for(let i = 0; i < args.other.length; i++){
                fields[args.other[i].name] = args.other[i].value;
            }
            changeFieldsBatch(fields);
        }else{
            changeField(field.name, args.id);
        }
    };

    const nestedField = field => {
        let nested_fields = field.split(".");
        let value = data[nested_fields[0]];
        if(value) {
            for (let i = 1; i < nested_fields.length; i++) {
                if (nested_fields[i]) {
                    value = value[nested_fields[i]];
                }
            }
        }
        return value;
    };

    const onAddition = tag => {
        tags.push(tag);
        setTags(tags);
        changeField(field.name, tags.map(tag => tag.name));
    };

    const onDelete = i => {
        tags.splice(i, 1);
        setTags(tags);
        changeField(field.name, tags.map(tag => tag.name));
    };

    const input = useMemo(() => {
        let inputField;
        let type = field.type ?? fieldModel.type;
        switch(type){
            case "image":
                inputField = <>
                    <StyledImage
                        width={fieldModel.width}
                        height={fieldModel.height}
                        upload={upload}
                    />
                    {value && <>
                        <img src={value} alt={fieldModel.title} className="icon"/>
                        <Button variant="danger" title="حذف" onClick={removeImage}>×</Button>
                    </>}
                </>;
                break;
            case "images":
                inputField = <>
                    <StyledImage
                        width={fieldModel.width}
                        height={fieldModel.height}
                        upload={upload}
                    />
                    {value && value.length > 0 && value.map(val =>
                        <>
                            <img src={val} alt={fieldModel.title} className="icon"/>
                            <Button variant="danger" title="حذف" onClick={removeImage}>×</Button>
                        </>
                    )}
                </>;
                break;
            case "simple_image":
                inputField = <>
                    <Form.Control
                        id={"simple-" + field.name}
                        type={"file"}
                        name={field.name}
                        onChange={upload}
                    />
                    {value && <>
                        <img src={value} alt={fieldModel.title} className="icon"/>
                        <Button variant="danger" title="حذف" onClick={removeImage}>×</Button>
                    </>}
                </>;
                break;
            case "textarea":
                inputField = <Form.Control
                    as="textarea"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                    rows={field.rows ?? 10}
                    style={field.style}
                />;
                break;
            case "select":
                inputField = <Form.Control
                    as="select"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                >
                    <option value={''}>{'-'}</option>
                    {fieldModel.items.map(item =>
                        <option value={item.key}>{item.value}</option>
                    )}
                </Form.Control>;
                break;
            case "select_data":
                inputField = <Form.Control
                    as="select"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                >
                    <option value={''}>{'-'}</option>
                    {foreignValues && foreignValues[field.name] && foreignValues[field.name].map(item =>
                        <option value={item.id}>{item.name}</option>
                    )}
                </Form.Control>;
                break;
            case "date":
                inputField = <DatePicker
                    onChange={changeDate}
                    value={dateField ?? moment()}
                    isGregorian={false}
                    timePicker={false}
                />;
                break;
            case "datetime":
                inputField = <DatePicker
                    onChange={changeDateTime}
                    value={dateField ?? moment()}
                    isGregorian={false}
                    timePicker={true}
                />;
                break;
            case "hidden":
                inputField = <Form.Control
                    type="hidden"
                    name={field.name}
                    value={value}
                />;
                break;
            case "password":
                inputField = <Form.Control
                    type="password"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                    autoComplete="new-password"
                />;
                break;
            case "map":
                if(base.model[field.name].radius){
                    mapCircle = {radius: data[base.model[field.name].radius]};
                }
                inputField = <Map
                    center={mapPosition}
                    marker={mapPosition}
                    circle={mapCircle}
                    click={clickOnMap}
                />;
                break;
            case "picker":
                let cp = "";
                if(id){
                    if(typeof fieldModel.caption === "string"){
                        cp = data[fieldModel.caption];
                    }else {
                        if(fieldModel.caption){
                            for (let i = 0; i < fieldModel.caption.length; i++) {
                                if (fieldModel.caption[i].static) {
                                    cp += fieldModel.caption[i].static;
                                } else {
                                    if(fieldModel.caption[i].indexOf('.') > -1){
                                        cp += nestedField(fieldModel.caption[i]);
                                    }else{
                                        cp += data[fieldModel.caption[i]];
                                    }
                                }
                            }
                        }
                    }
                    if(!cp && foreignValues && foreignValues[field.name]){
                        let res = foreignValues[field.name].find(item => item.id === value);
                        if(res){
                            cp = res.name;
                        }
                    }
                }
                inputField = <Picker props={{
                    module: fieldModel.module,
                    field: field.name,
                    field_title: base.model[field.name].title,
                    other_fields: fieldModel.other_fields,
                    cp,
                    result: changePicker
                }}/>;
                break;
            case "checkbox":
                inputField = <Form.Check
                    type="checkbox"
                    name={field.name}
                    checked={value}
                    label={fieldModel.title}
                    id={`inline-checkbox-${value}`}
                    onChange={fieldChange}
                />;
                break;
            case "radio":
                inputField = <div>{fieldModel.items.map(item =>
                    <Form.Check
                        inline
                        type="radio"
                        name={field.name}
                        value={item.key}
                        label={item.value}
                        checked={item.key !== undefined && value !== undefined ? item.key.toString() === value.toString() : false}
                        id={`inline-radio-${item.value}`}
                        onChange={fieldChange}
                    />
                )}</div>;
                break;
            case "function":
                let params = {fieldChange};
                let param;
                for(let i = 0; i < field.value.params.length; i++){
                    param = field.value.params[i];
                    params[param] = data[param];
                }
                inputField = field.value.func(params);
                break;
            case "tag_items":
                inputField = <ReactTags
                    tags={tags}
                    suggestions={fieldModel.items.map(item => {return {id: item.key, name: item.value}})}
                    onAddition={onAddition}
                    onDelete={onDelete}
                />;
                break;
            case "tag_data":
                inputField = <ReactTags
                    tags={tags}
                    suggestions={foreignValues[field.name]}
                    onAddition={onAddition}
                    onDelete={onDelete}
                />;
                break;
            case "json":
                inputField = <Form.Control
                    type="text"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                    style={{direction: 'ltr', textAlign: 'left'}}
                />;
                break;
            default:
                inputField = <Form.Control
                    type="text"
                    name={field.name}
                    value={value}
                    onChange={fieldChange}
                    style={field.style}
                />;
                break;
        }
        return inputField;
    }, [data, foreignValues]);

    let type = field.type ?? fieldModel.type;

    return <Form.Group>
        {type === "checkbox" ?
            input
            :
            <>
                <Form.Label>{fieldModel.title}</Form.Label>
                {uploading ? <Loading/> : input}
            </>
        }
    </Form.Group>;
}